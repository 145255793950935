
import LoadingDiv from '../Components/Indicators/LoadingDiv'

export default function PrivacyPolicy () {
  const p = "text-base block mb-5"
  const h2 = "text-4xl mt-10"
  const list = "list-inside pl-10 list-disc" + " " + p
  const a = "text-blue-500"
  return (
    <LoadingDiv isLoading={false} className="max-w-7xl w-full mx-auto bg-white mt-8 p-8 rounded md:w-5/6">
        <h2 className={h2}>FITICAST PRIVACY POLICY</h2>
        <span className={p}>
          (Last Updated Decemeber 5, 2020)
        </span>
        <span className={p}>
          Through the www.fiticast.com website and related mobile application, KPEF LLC (“FitiCast”, “we”, “us” or “our”) may provide you with access to workout and fitness resources (collectively, the “Services”) This Privacy Policy sets forth our policies with respect to information including personally identifiable data (“Personal Data”) and other information that is collected from you when using the Services. This Privacy Policy is incorporated by reference in the FitiCast End User Terms of Use, which govern your use of the Services.
        </span>
        <span className={p}>
          This Privacy Policy (“Statement”) applies to persons anywhere in the world who use our apps or Services to plan personalized workouts (“Users”).
        </span>
        <span className={p}>
          By voluntarily providing us with Personal Data, you are consenting to our use of it in accordance with this Privacy Policy.
        </span>


        <h2 className={h2}>COLLECTION OF INFORMATION</h2>
        <span className={p}>
          INFORMATION YOU PROVIDE TO US
        </span>
        <span className={p}>
          We may collect Personal Data you voluntarily provide directly to us, such as when you enter personal information, workout information, your health, fitness and/or body data, contact customer support, or otherwise communicate with us, as set forth below. 
        </span>
        <span className={p}>
          If you provide Personal Data through the Services, you acknowledge and agree that such Personal Data may be transferred from your current location to our offices and servers or those of our contractors located in the United States and other countries. However, we will cease using your Personal Data once you cancel or terminate your registration for the Services.
        </span>
        <span className={p}>
          INFORMATION WE COLLECT THROUGH YOUR USE OF OUR SERVICES
        </span>

        <span className={p} >
          When you use our Services, we may collect information about you in the following general categories:
        </span>
        <ul className={list}>
          <li>Usage and Preference Information: We collect information about how you and site visitors interact with our Services, preferences expressed, and settings chosen. In some cases, we do this through the use of cookies, pixel tags, and similar technologies that create and maintain unique identifiers.</li>
          <li>Log Information: When you interact with the Services, we may collect server logs, which may include information like device IP address, access dates and times, app features or pages viewed, app crashes and other system activity, type of browser, and the third-party site or service you were using before interacting with our Services.</li>
          <li>Information you provide to us: When you interact with the Services you may elect to provide your email address and other personal information.</li>
        </ul>
        <span className={p}>
          USE OF INFORMATION        
        </span>
        <span className={p}>
          We may use the information we collect about you to:     
        </span>
        <ul className={list}>
          <li>Provide, maintain, and improve our Services, including, for example, to monitor what Services you use, do not use, provide products and services you request (and send related information), develop new features, develop safety features, and send product updates and administrative messages.</li>
          <li>Perform internal operations, including, for example, to prevent fraud and abuse of our Services; to troubleshoot software bugs and operational problems; to conduct data analysis, testing, and research; and to monitor and analyze usage and activity trends.</li>
          <li>Personalize and improve the Services, including to provide or recommend features, content, social connections, referrals, and advertisements.</li>
        </ul>
        <span className={p}>
          SHARING OF INFORMATION      
        </span>
        <span className={p}>
          We may share the information we collect about you as described in this Statement or as described at the time of collection or sharing, including as follows: 
        </span>
        <span className={p}>
          THROUGH OUR SERVICES 
        </span>
        <ul className={list}>
          <li>
            <span className="font-bold">Firebase</span> Firebase is an analytics service provided by Google Inc.You may opt-out of certain Firebase features through your mobile device settings, such as your device advertising settings or by following the instructions provided by Google in their Privacy Policy: <a href="https://policies.google.com/privacy?hl=en" className={a}>https://policies.google.com/privacy?hl=en</a> We also encourage you to review the Google's policy for safeguarding your data: <a href="https://support.google.com/analytics/answer/6004245" className={a}>https://support.google.com/analytics/answer/6004245</a>. For more information on what type of information Firebase collects, please visit the Google Privacy and Terms web page: <a className={a} href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a>
          </li>
        </ul>
        <span className={p}>
          OTHER IMPORTANT SHARING
        </span>
        <span className={p}>
          We may share your information:
        </span>
        <ul className={list}>
          <li>
            In response to a request for information by a competent authority if we believe disclosure is in accordance with, or is otherwise required by, any applicable law, regulation, or legal process;
          </li>
          <li>
            With law enforcement officials, government authorities, or other third parties if we believe your actions are inconsistent with our User agreements, Terms of Service, or policies, or to protect the rights, property, or safety of FitiCast or others;
          </li>
          <li>
            In connection with, or during negotiations of, any merger, sale of company assets, consolidation or restructuring, financing, or acquisition of all or a portion of our business by or into another company;
          </li>
          <li>
            If we otherwise notify you and you consent to the sharing; and
          </li>
          <li>
            In an aggregated and/or anonymized form which cannot reasonably be used to identify you.
          </li>
        </ul>
        <h2 className={h2}>YOUR CHOICES</h2>
        <span className={p}>
          ACCESS RIGHTS
        </span>
        <span className={p}>
          FitiCast will comply with individual’s requests regarding access, correction, and/or deletion of the personal data it stores in accordance with applicable law.
        </span>

        <span className={p}>
          PROMOTIONAL COMMUNICATIONS
        </span>
        <span className={p}>
          You may opt out of receiving promotional messages from us by following the instructions in those messages. If you opt out, we may still send you non-promotional communications, such as those about your account, about Services you have requested, or our ongoing business relations.
        </span>

        <span className={p}>
          YOUR CALIFORNIA PRIVACY RIGHTS
        </span>
        <span className={p}>
          If you are a California resident, California law may provide you with additional rights regarding our use of your personal information. To learn more about your California privacy rights, visit the < a className={a} href="http://fitbod.me/ccpa-privacy-policy">California Privacy Rights Statement for California Residents</a>.
        </span>

        <span className={p}>
          CHILDREN
        </span>
        <span className={p}>
          We do not knowingly collect Personal Data from children under the age of 13 and do not target our websites to children under 13. If you are under thirteen, please do not submit any Personal Data through the Services. We encourage parents and legal guardians to monitor their children’s Internet usage and to help enforce our Privacy Policy by instructing their children to never provide Personal Data through any of our Services without their permission. If you have reason to believe that a child under the age of 13 has provided Personal Data to us, please contact us, and we will endeavor to delete that information from our databases.
        </span>

        <span className={p}>
          CHANGES TO THE STATEMENT
        </span>
        <span className={p}>
          We may change this Statement from time to time. If we make significant changes in the way we treat your personal information, or to the Statement, we will provide you notice through the Services or by some other means, such as email. Your continued use of the Services after such notice constitutes your consent to the changes. We encourage you to periodically review the Statement for the latest information on our privacy practices.
        </span>

        <span className={p}>
          CONTACT US
        </span>
        <span className={p}>
          If you have any additional questions or concerns about this Privacy Policy, please feel free to contact us at <a href="mailto:contact@fitearn.app" className={a}>contact@fitearn.app</a>.
        </span>
    </LoadingDiv>
  )
}