import React from "react";
import ReactDOM from "react-dom";
import './index.css';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Landing from './Landing/Landing'
import FitiFooter from './Layout/Footer/FitiFooter'
import RequestDemo from './RequestDemo/RequestDemo'
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy'
import Helmet from 'react-helmet';
import NavBar from "./Layout/NavBar/NavBar"
import navState from "./Layout/NavBar/NavState"


const NavigatetoPage = (props) => {
  navState.showRequestDemo = props.showRequestDemo
  return (
    <React.Fragment>
      {props.children}
    </React.Fragment>
  )
}


ReactDOM.render(
  <BrowserRouter>
    <Helmet bodyAttributes={{style: 'background-color : rgba(249, 249, 249)'}}/>
      <NavBar />
      <Route path="/" exact>
        <NavigatetoPage showRequestDemo={true}>
          <Landing />
        </NavigatetoPage>
      </Route>
      <Route path="/privacy-policy">
        <NavigatetoPage showRequestDemo={false}>
          <PrivacyPolicy />
        </NavigatetoPage>
      </Route>
      <Route path="/request-demo">
        <NavigatetoPage showRequestDemo={false}>
          <RequestDemo />
        </NavigatetoPage>
      </Route>
      <FitiFooter />
  </BrowserRouter>,
  document.getElementById("root")
);