import React from "react";

const TopBar = () => {
    return (
        <div className="sm:h-96 h-screen w-screen relative">
            <video className="w-full h-full object-cover absolute" src="/videos/header.mp4" preload autoPlay muted loop></video>
            <div className="w-full h-full absolute" style={{ "background-color": "rgba(0, 0, 0, 0.1)"}}></div>
            <h2 className="absolute text-6xl text-white text-center w-full sm:mt-16 mt-32 font-semibold">
                <span className="block">Workouts are Better</span>
                <span className="block">with Music</span>
            </h2>
            <div className="absolute w-full bottom-8 text-center text-lg text-white">
                <span className="block">Live Stream Fitness Classes</span>
                <span className="block">With Music From Spotify or Apple Music</span>
            </div>
        </div>
    )
}
 

export default TopBar;