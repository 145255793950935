import { HomeIcon } from "@heroicons/react/outline";
import React from "react";
import CardInfo from "./CardInfo"
 

const instructorInfo = {
  title: 'Your Expertise',
  subTitle: 'You Teach. We Tech.',
  description: 'With just a few taps, setup your class and manage, class roster, and start teaching.',
  icon: 'chalkboard-teacher'
};

const classInfo = {
  title: '70 Million Songs',
  subTitle: 'Access Apple Music or Spotify',
  description: '(TODO, say what musis is used for) Apple Music or Spotify? Your Choice. FitiCast allows students and instructors to use either.',
  icon: 'headphones'
};

const whereeverInfo = {
  title: 'Anywhere',
  subTitle: 'Flexibility is Freedom',
  description: 'Whether it\'s the studio, park or just at home, create the best environment for your classes.',
  icon: "globe-americas"
};


const CardInfoContainer = () => {
    return (
        <div className="relative max-w-7xl mx-auto mt-4">
            <div className="max-w-lg mx-auto grid gap-5 md:grid-cols-3 md:max-w-none">
                <CardInfo title={instructorInfo.title} subTitle={instructorInfo.subTitle} description={instructorInfo.description} icon={instructorInfo.icon}/>
                <CardInfo title={classInfo.title} subTitle={classInfo.subTitle} description={classInfo.description} icon={classInfo.icon}/>
                <CardInfo title={whereeverInfo.title} subTitle={whereeverInfo.subTitle} description={whereeverInfo.description} icon={whereeverInfo.icon}/>
            </div>
        </div>
    );
};

export default CardInfoContainer;