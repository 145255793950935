import BeatLoader from 'react-spinners/BeatLoader'

// https://github.com/davidhu2000/react-spinners
const LoadingDiv = (props) => {
    const css = `
        margin: auto;
        top: 32px;
        left:11px;
        position: relative;
        margin-top:-10px;
    `
    const className = props.className || ''
    const classNames = className.split(' ')
    if (!classNames.includes('relative')) {
        classNames.push('relative')
    }

    if (!classNames.includes('overflow-hidden')) {
        classNames.push('overflow-hidden')
    }
    const newClassName = classNames.join(' ')

    const loadingSection = () => {
        if (props.isLoading) {
            return (            
                <div className="absolute w-full h-full bg-opacity-50 bg-white z-50">
                    <div className="w-20 h-20 -mt-10 m-auto top-1/2 left-1/2 -mx-10 absolute">
                    <BeatLoader css={css} size={15} />
                </div>
            </div>
            )
        }
        else {
            return <div></div>
        }
    }

    return (
        <div className={newClassName}>
            {loadingSection()}
            {props.children}

        </div>
    )
}

export default LoadingDiv
