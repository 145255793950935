import CardInfoContainer from "./CardInfoContainer"
import TopBar from "./TopBar"
import DownloadiOS from "./DownloadiOS"

export default function Landing () {
  return (
    <div className="bg-gray-100 bg-opacity-50">
      <div>
        <main>
          <div className="max-w-full mx-auto">
            <TopBar />
            <CardInfoContainer />
            <DownloadiOS />
          </div>
        </main>
      </div>
    </div>
  )
}