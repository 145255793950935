import React, { Component } from 'react';

class InputWrapper extends Component {
    render() {
        return (
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                {this.props.children}
            </div>
        );
    }
}

export default InputWrapper
