import {  Fragment } from 'react'

const TextField = (props) => {
    const onFieldChange = event => {
        props.write(event.target.value)
    }

    return (
        <Fragment>
            <label htmlFor={props.id} className="block text-sm font-medium text-gray-700 md:mt-px md:pt-2">
                {props.label}
            </label>
            <div className="mt-1 md:mt-0 md:col-span-2">
                <input id={props.id} name={props.id} type="email" autoComplete="email" className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 md:max-w-xs md:text-sm border-gray-300 rounded-md" value={props.value} onChange={onFieldChange} />
            </div>
        </Fragment>
    )
    
}

export default TextField