import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faChalkboardTeacher, faHeadphones, faGlobeAmericas } from '@fortawesome/pro-solid-svg-icons'

library.add(fab, faChalkboardTeacher, faHeadphones, faGlobeAmericas)


class CardInfo extends Component {
    render() {
    return (
        <div className="flex flex-col rounded-lg xshadow-lg overflow-hidden">
            <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                    <p className="text-3xl text-gray-900 text-center font-extralight">{this.props.title}</p>                    
                    <span className="block text-center font-semibold">
                        {this.props.subTitle}
                    </span>
                    <div className="mt-1 flex justify-center">
                        <FontAwesomeIcon icon={this.props.icon} size="lg" color="#937EE7"/>
                     </div>
                    <p className="mt-1 text-lg text-gray-500 text-center">{this.props.description}</p>
                    <div className="flex justify-center">
                        <img className="h-128 md:h-72" src='/images/phone.jpg'/>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}

export default CardInfo;
