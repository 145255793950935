import React from "react";

const DownloadiOS = () => {
    return (
        <div className="mt-8">
            <div className="h-16 flex justify-center items-center">
                <a className="h-full" href="https://apps.apple.com/us/app/fiticast/id1548825867">
                    <img className="h-full" src='/images/download_app_store.svg' />
                </a>
            </div>
        </div>
    );
};
export default DownloadiOS;