import InputWrapper from '../Components/Forms/InputWrapper'
import TextField from '../Components/Forms/TextField'
import EmailField from '../Components/Forms/EmailField'
import Submission from '../Components/Forms/Submission'
import FormIntro from '../Components/Forms/FormIntro'
import FormWrapper from '../Components/Forms/FormWrapper'
import LoadingDiv from '../Components/Indicators/LoadingDiv'
import { useState } from 'react'
import service from '../Service/APIService'


export default function RequestDemo () {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [company, setCompany] = useState('')
  const [email, setEmail] = useState('')
  const [isFormVisible, setIsFormVisible] = useState(true)
  const [error, setError] = useState('')

  const [isLoading, setIsLoading] = useState(false)

  const onFormSubmit = event => {
    event.preventDefault()
    const data = {
      email: email,
      firstName: firstName,
      lastName: lastName,
      company: company
    }

    setIsLoading(true)
    service.post('/request-demo', data)
    .then ((response) => {
      setIsFormVisible(false)
    })
    .catch((err) => {
      console.error(err)
    })
    .finally ( () => {
      setIsLoading(false)
    })
  }

  return (
    <LoadingDiv isLoading={isLoading} className="max-w-7xl w-full mx-auto bg-white mt-8 p-8 rounded md:w-5/6">
      <div style={{display: isFormVisible ? 'none' : 'block' }} className="pt-20 pb-20 pl-10 pr-10 font-light text-center text-5xl" >
        Thank You. We Will Be In Touch Shortly.
      </div>
      <form style={{display: isFormVisible ? 'block' : 'none' }} onSubmit={onFormSubmit} className="p-9 block">
        <FormWrapper>
          <FormIntro 
            title="Request a Demo" 
            subTitle="Send us over your information and we will get a demo scheduled ASAP!" />
          <InputWrapper>
            <TextField id="first_name" label="First Name *" write={setFirstName} value={firstName} />
          </InputWrapper>

          <InputWrapper>
            <TextField id="last_name" label="Last Name *" write={setLastName} value={lastName} />
          </InputWrapper>

          <InputWrapper>
              <TextField id="company" label="Company" write={setCompany} value={company} />
          </InputWrapper>

          <InputWrapper>
              <EmailField id="email" label="Email *" write={setEmail} value={email} />
          </InputWrapper>
          <Submission buttonTitle="Request Demo" />
        </FormWrapper>
      </form>
    </LoadingDiv>
  )
}